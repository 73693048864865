import { React } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@mui/material'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDInput from 'components/MDInput'

export default function CommandBar({ commands }) {
    const startCommands = commands?.filter((command) => !command.position || command.position === 'start')
    const endCommands = commands?.filter((command) => command.position === 'end')

    const commandSchemes = {
        button: {
            control: MDButton,
            component: null,
            canBeDisabled: true,
        },
        link: {
            control: MDButton,
            component: Link,
            canBeDisabled: false,
        },
        input: {
            control: MDBox,
            component: MDInput,
            canBeDisabled: true,
        },
    }

    const renderCommands = (commands) => {
        return commands?.map((command, index) => {
            const {
                scheme,
                label,
                color,
                icon,
                to,
                onClick,
                disabled,
                ...props
            } = command

            const {
                control: Control,
                component,
                canBeDisabled,
            } = commandSchemes[scheme || 'button']

            const iconOnly = icon == null
                ? null
                : (label == null)

            const isDisabled = canBeDisabled && disabled ? true : null

            const controlProps = { ...props }
            if (iconOnly !== null) {
                controlProps['iconOnly'] = iconOnly
            }
            if (isDisabled === true) {
                controlProps['disabled'] = isDisabled
            }

            return (
                <Control
                    color={color || 'primary'}
                    variant="contained"
                    component={component}
                    to={to}
                    key={index}
                    onClick={onClick}
                    {...controlProps}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.target.click()
                        }
                    }}
                >
                    {icon != null ? <Icon>{icon}</Icon> : null}
                    {label}
                </Control>
            )
        })
    }

    return (
        <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            mt={1}
            mb={3}
        >
            <MDBox display="flex" alignItems="flex-start" gap="1rem">
                {renderCommands(startCommands)}
            </MDBox>
            <MDBox display="flex" alignItems="flex-end" gap="1rem">
                {renderCommands(endCommands)}
            </MDBox>
        </MDBox>
    )
}
