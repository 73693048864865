/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useContext, useEffect, useState } from 'react'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @material-ui core components
import { AppBar, Divider, Icon, IconButton, ListItemIcon, Menu, MenuItem, Toolbar } from '@mui/material'

import AuthContext from 'units/auth/AuthContext'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'

// Material Dashboard 2 PRO React examples
import Breadcrumbs from 'examples/Breadcrumbs'

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarDesktopMenu,
    navbarIconButton,
    navbarRow,
} from 'examples/Navbars/DashboardNavbar/styles'

// Material Dashboard 2 PRO React context
import {
    setMiniSidenav,
    setOpenConfigurator,
    setTransparentNavbar,
    useMaterialUIController,
} from 'context/material-ui'

import PlatformContext from 'context/platform/PlatformContext'
import MDTypography from 'components/MDTypography'
import useName from 'hooks/useName'
import { AppMessageProvider } from '../../../context/messaging/AppMessageContext'
import Flash from '../../../components/Flash/Flash.component'

function DashboardNavbar({ absolute, light, isMini, children }) {
    const [navbarType, setNavbarType] = useState()
    const [controller, dispatch] = useMaterialUIController()
    const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller
    const [openMenu, setOpenMenu] = useState(false)

    const authenticator = useContext(AuthContext)

    // const fullPath = useLocation().pathname
    // // const currentPathPart = fullPath.split('/').slice(1)
    // const route = fullPath.split('/').slice(1)
    // const title = route[route.length - 1]
    // // const route = routes.find(route => route.route)

    const name = useName()

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType('sticky')
        }
        else {
            setNavbarType('static')
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar)
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener('scroll', handleTransparentNavbar)

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar()

        // Remove event listener on cleanup
        return () => window.removeEventListener('scroll', handleTransparentNavbar)
    }, [dispatch, fixedNavbar])

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav)
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator)
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget)
    const handleCloseMenu = () => setOpenMenu(false)

    const handleSignOutMenuItem = () => {
        authenticator.logOut()
    }

    const [version, setVersion] = useState('')
    const platform = useContext(PlatformContext)

    const handleUpgradeMenuItem = () => {
        platform.checkForUpdates()
    }

    useEffect(() => {
        const getVersion = async () => {
            const appVersion = await platform.getAppVersion()
            setVersion(appVersion)
        }

        getVersion()
    })

    // Render the notifications menu
    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 0 }}
        >
            <MenuItem
                // icon={<Icon>logout</Icon>}
                // title="התנתקות"
                onClick={handleSignOutMenuItem}
            >
                <ListItemIcon>
                    <Icon>logout</Icon>
                </ListItemIcon>
                התנתקות
            </MenuItem>
            <Divider />
            <MDBox
                align="center"
                color="inherit"
                sx={{
                    direction: 'rtl',
                }}
            >
                {version}
            </MDBox>
            {platform.isDesktopApp
                ? <MenuItem
                    onClick={handleUpgradeMenuItem}
                >
                    <ListItemIcon>
                        <Icon>upgrade</Icon>
                    </ListItemIcon>
                    עדכון גרסה
                </MenuItem>
                : null}

            {/* <DefaultItem
                icon={<Icon>logout</Icon>}
                title="התנתקות"
                onClick={() => logOut()}
              /> */}
            {/* <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
              <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
              <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" /> */}
        </Menu>
    )

    // Styles for the navbar icons
    const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main

            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main
            }

            return colorValue
        },
    })

    return (
        <AppBar
            position={absolute ? 'absolute' : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
                    <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
                        <Icon fontSize="medium" sx={iconsStyle}>
                            {miniSidenav ? 'menu_open' : 'menu'}
                        </Icon>
                    </IconButton>
                    <Breadcrumbs
                        icon="home"
                        // title="abc"
                        // title={title}
                        // route={route.route}
                        // route={route}
                        light={light}
                    />
                </MDBox>
                {isMini ? null : (
                    <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
                        <AppMessageProvider>
                            <Flash light={light} />
                        </AppMessageProvider>
                        <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="regular"
                            color={light ? 'white' : 'dark'}
                            opacity={light ? 0.8 : 0.5}
                            sx={{
                                lineHeight: 0,
                                fontSize: '1.2rem',
                            }}
                        >
                            {name}
                        </MDTypography>
                        {/* <MDBox pr={1}>
                          <MDInput label="Search here" />
                        </MDBox> */}
                        <MDBox color={light ? 'white' : 'inherit'}>
                            {/* <Link to="/authentication/sign-in/basic">
                                <IconButton sx={navbarIconButton} size="small" disableRipple>
                                  <Icon sx={iconsStyle}>account_circle</Icon>
                                </IconButton>
                              </Link>
                              <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                              >
                                <Icon sx={iconsStyle} fontSize="medium">
                                  {miniSidenav ? 'menu_open' : 'menu'}
                                </Icon>
                              </IconButton>
                              <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarIconButton}
                                onClick={handleConfiguratorOpen}
                              >
                                <Icon sx={iconsStyle}>settings</Icon>
                              </IconButton>
                              <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarIconButton}
                                aria-controls="notification-menu"
                                aria-haspopup="true"
                                variant="contained"
                                onClick={handleOpenMenu}
                              >
                                <MDBadge badgeContent={9} color="error" size="xs" circular>
                                  <Icon sx={iconsStyle}>notifications</Icon>
                                </MDBadge>
                              </IconButton> */}
                            <IconButton
                                sx={navbarIconButton}
                                size="small"
                                disableRipple
                                aria-haspopup="true"
                                variant="contained"
                                onClick={handleOpenMenu}
                            >
                                <Icon sx={iconsStyle}>settings</Icon>
                            </IconButton>
                            {renderMenu()}
                        </MDBox>
                    </MDBox>
                )}
            </Toolbar>
            {children}
        </AppBar>
    )
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
}

export default DashboardNavbar
