import { React } from 'react'
import schema from '../../schemas/entry'
import SchemaTable from '../table/SchemaTable'
import MDButton from '../MDButton'

export default function ({
    entries,
    busyEntryIds,
    navigateToEntry,
    deleteEntry,
}) {
    const fieldIds = ['id', 'status', 'numberOfDocuments', 'documentTypes', 'createdAt', 'updatedAt']

    const actionsColumn = {
        Header: 'פעולה',
        width: 300,
        accessor: 'action',
        enableSorting: false,
        Cell: (cell) => {
            const { id, status, disabled } = cell.row.values
            const isDisabled =
                disabled
                || (status && ['compiled', 'sent'].includes(status.toLowerCase()))
            return (<>
                <MDButton
                    disabled={isDisabled}
                    onClick={(e) => {
                        e.stopPropagation()
                        deleteEntry(id)
                    }}
                    variant="outlined"
                    color="warning"
                    sx={{
                        marginInlineEnd: 1,
                    }}
                >
                    מחיקה
                </MDButton>
            </>)
        },
    }

    const nonNullEntries = entries || []
    const entriesWithDisabledStatus = nonNullEntries.map((entry) => {
        const { id: entryId } = entry
        const disabled = busyEntryIds.includes(entryId)
        return {
            ...entry,
            disabled,
        }
    })

    return (
        <SchemaTable
            schema={schema}
            data={entriesWithDisabledStatus}
            fieldIds={fieldIds}
            sortFieldId={'updatedAt'}
            columns={[actionsColumn]}
            canSearch={true}
            onRowClick={(e, row) => {
                const entryId = row.original.id
                navigateToEntry(entryId)
            }}
        />
    )
}
