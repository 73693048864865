import { React } from 'react'
import PropTypes from 'prop-types'
import { companyTypes, reportingPeriodType } from './companyTypes'
import colors from '../../assets/theme/base/colors'
// react-router-dom components
import { Link } from 'react-router-dom'

import {
    Card,
    CardContent,
    CardActionArea,
    CardActions,
    Icon,
} from '@mui/material'

import MDBox from '../MDBox'
import MDTypography from '../MDTypography'
import StatusBadge, { statuses } from '../StatusBadge'

export default function CompanyCard({ company, children }) {

    function formatCompany() {
        const companyParts = []
        const companyType = companyTypes[company.companyType]
        if (companyType) {
            companyParts.push(companyType)
        }
        const reportingPeriod = reportingPeriodType[company.reportingPeriodType]
        if (reportingPeriod) {
            companyParts.push(reportingPeriod)
        }
        const companyDetail = companyParts.join(', ')
        return companyDetail
    }

    function formatStreet() {
        const street = company.street === null ? undefined : company.street
        const addressNumber = company.addressNumber === null ? undefined : company.addressNumber
        const streetAddress = [
            street,
            addressNumber,
        ].join(' ').trim()

        const addressParts = []
        if (streetAddress.length > 0) {
            addressParts.push(streetAddress)
        }
        if (company.city) {
            addressParts.push(company.city)
        }
        const address = addressParts.join(', ')
        return address
    }

    let entriesStatusText
    let entriesStatusColor = statuses.valid.color
    const cardEntries = company.entries
    if (cardEntries.length === 0) {
        entriesStatusText = 'אין רשומות'
        entriesStatusColor = statuses.new.color
    }
    else {
        const invalidEntries = cardEntries.filter(entry => {
            return entry.status === 'Invalid'
        })
        entriesStatusText = `${cardEntries.length} רשומות`
        if (invalidEntries.length > 0) {
            entriesStatusText = `${entriesStatusText}, ${invalidEntries.length} לא תקינות`
            entriesStatusColor = statuses.invalid.color
        }
    }

    const address = formatStreet()
    const companyDetail = formatCompany()

    const { grey } = colors

    const content =
        <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            px={1}
            py={2}
        >
            <MDBox display="flex">
                <MDTypography variant="h3">
                    {company.companyName || '* ללא שם *'}
                </MDTypography>
            </MDBox>
            <StatusBadge
                status={entriesStatusText}
                color={entriesStatusColor}
                circular={false}
                variant="contained"
                size="md"
                sx={{
                    paddingInline: 0,
                    '& .MuiBadge-badge': {
                        marginInline: 0,
                    },
                }}
            />
            <MDTypography variant="body1">
                {address}
            </MDTypography>
            <MDTypography variant="body2">
                {companyDetail}
            </MDTypography>
            {children}
        </MDBox>

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                overflow: 'visible',
            }}
        >
            <CardActionArea
                component={Link}
                to={`/companies/${company.id}/entries`}
                sx={{
                    flexGrow: 1,
                }}
            >
                <CardContent>
                    {content}
                </CardContent>
            </CardActionArea>
            <CardActions
                sx={{
                    justifyContent: 'flex-end',
                    paddingInline: 2,
                    backgroundColor: grey[300],
                    borderEndEndRadius: 10,
                    borderEndStartRadius: 10,
                }}
            >
                <Link p={1} to={`${company.id}`}><Icon>edit</Icon></Link>
            </CardActions>
        </Card>
    )
}

// Setting default values for the props of CompanyCard
CompanyCard.defaultProps = {
    // authors: [],
    // mode: 'buttons',
}

// Typechecking props for the CompanyCard
CompanyCard.propTypes = {
    // image: PropTypes.string,
    // label: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    // action: PropTypes.shape({
    //   type: PropTypes.oneOf(['external', 'internal']),
    //   route: PropTypes.string.isRequired,
    //   color: PropTypes.oneOf([
    //     'primary',
    //     'secondary',
    //     'info',
    //     'success',
    //     'warning',
    //     'error',
    //     'light',
    //     'dark',
    //     'white',
    //   ]),
    //   label: PropTypes.string,
    // }).isRequired,
    // authors: PropTypes.arrayOf(PropTypes.object),
    // mode: PropTypes.oneOf(['buttons', 'card']),
}
