import { useContext } from 'react'
import {
    fetch,
    fetchMinimalCompanyInfo,
} from '../../helpers/fetch.helper'
import AuthContext from '../../units/auth/AuthContext'
import AuthStorage from '../../units/auth/AuthStorage'
import FetchContext from './FetchContext'

export default function FetchProvider({ children, version }) {
    const authenticator = useContext(AuthContext)
    const authStorage = new AuthStorage()

    function contextualFetch(options) {
        const { headers, ...rest } = options

        const contextualOptions = {
            ...rest,
            headers: {
                ...headers,
                'X-Client-Version': version,
            },
        }
        return fetch(contextualOptions)
    }

    async function authenticate(data) {
        const { jwt, user } = await contextualFetch({
            method: 'post',
            path: `/auth/local`,
            data,
            responseHandler: (response) => response.data,
            authorize: false,
        })
        await authenticator.updateAuthentication(jwt, user)
    }

    async function authenticatedFetch(options) {
        const token = authStorage.getAuthenticationToken()
        if (token != null) {
            const { headers, ...rest } = options
            const authenticatedOptions = {
                ...rest,
                headers: {
                    ...headers,
                    'Authorization': `Bearer ${token}`,
                },
            }
            return contextualFetch(authenticatedOptions)
        }
        else {
            throw new Error('Not authenticated. Cannot fetch.')
        }
    }

    async function fetchDocument(id) {
        return authenticatedFetch({
            path: `/documents/${id}`,
        })
    }

    async function fetchDocumentForDocumentPage(id) {
        const params =
            {
                params: {
                    'fields[0]': 'id',
                    'fields[1]': 'type',
                    'fields[2]': 'kind',
                    'fields[3]': 'editor',
                    'fields[4]': 'schedule',
                    'fields[5]': 'status',
                    'populate[entry][fields][0]': 'id',
                    'populate[entry][fields][1]': 'status',
                    'populate[check][fields][0]': 'id',
                    'populate[check][fields][1]': 'number',
                    'populate[check][fields][2]': 'bank',
                    'populate[check][fields][3]': 'branch',
                    'populate[check][fields][4]': 'account',
                    'populate[check][fields][5]': 'currency',
                    'populate[voucher27][fields][0]': 'id',
                    'populate[voucher27][fields][1]': 'asmachtaA',
                    'populate[voucher27][fields][2]': 'beneficiaryCode',
                    'populate[voucher27][fields][3]': 'asmachtaB',
                    'populate[voucher27][fields][4]': 'currency',
                    'populate[voucher42][fields][0]': 'id',
                    'populate[voucher42][fields][1]': 'barcode',
                    'populate[voucher38][fields][0]': 'id',
                    'populate[voucher38][fields][1]': 'barcode',
                    'populate[images][fields][0]': 'id',
                    'populate[images][fields][1]': 'type',
                    'populate[images][populate][file][fields][0]': 'id',
                    'populate[images][populate][file][fields][1]': 'url',
                },
            }
        return authenticatedFetch({
            path: `/documents/${id}`,
            ...params,
        })
    }

    async function fetchUser() {
        return authenticatedFetch({
            path: `/users/me`,
            responseHandler: (response) => response.data,
        })
    }

    async function fetchOneCompany(id) {
        return authenticatedFetch({
            path: `/companies/${id}`,
        })
    }

    async function fetchCompanyName(id) {
        const company = await fetchOneCompany(id)
        return company.companyName
    }

    async function fetchEntriesForEntriesPage(company) {
        const params =
            {
                params: {
                    'populate[documents][fields][0]': 'id',
                    'populate[documents][fields][1]': 'type',
                    'filters[company][id]': company.id,
                },
            }
        const entries = await authenticatedFetch({
            path: `/entries`,
            ...params,
        })
        return entries
    }

    async function fetchEntryForEntryPage(id) {
        const params =
            {
                params: {
                    'fields[0]': 'id',
                    'fields[1]': 'status',
                    'populate[documents][fields][0]': 'id',
                    'populate[documents][fields][1]': 'type',
                    'populate[documents][fields][2]': 'kind',
                    'populate[documents][fields][3]': 'editor',
                    'populate[documents][fields][4]': 'currency',
                    'populate[documents][fields][5]': 'schedule',
                    'populate[documents][fields][6]': 'status',
                    'populate[documents][populate][images][fields][0]': 'id',
                    'populate[documents][populate][images][fields][1]': 'type',
                    'populate[documents][populate][images][populate][file][fields][0]': 'id',
                    'populate[documents][populate][images][populate][file][fields][1]': 'url',
                    'populate[documents][populate][check][fields][0]': 'id',
                    'populate[documents][populate][check][fields][1]': 'number',
                    'populate[documents][populate][check][fields][2]': 'createdAt',
                    'populate[documents][populate][voucher42][fields][0]': 'id',
                    'populate[documents][populate][voucher42][fields][1]': 'barcode',
                    'populate[documents][populate][voucher42][fields][2]': 'beneficiaryCode',
                    // 'populate[documents][populate][voucher42][fields][2]': 'voucherType',
                    'populate[documents][populate][voucher42][fields][3]': 'createdAt',
                    'populate[documents][populate][voucher42][fields][4]': 'path',
                    'populate[documents][populate][voucher38][fields][0]': 'id',
                    'populate[documents][populate][voucher38][fields][1]': 'barcode',
                    'populate[documents][populate][voucher38][fields][2]': 'beneficiaryCode',
                    // 'populate[documents][populate][voucher38][fields][2]': 'voucherType',
                    'populate[documents][populate][voucher38][fields][3]': 'createdAt',
                    'populate[documents][populate][voucher38][fields][4]': 'path',
                    'populate[documents][populate][voucher27][fields][0]': 'id',
                    'populate[documents][populate][voucher27][fields][1]': 'barcode',
                    'populate[documents][populate][voucher27][fields][2]': 'beneficiaryCode',
                    // 'populate[documents][populate][voucher27][fields][2]': 'voucherType',
                    'populate[documents][populate][voucher27][fields][3]': 'createdAt',
                    'populate[documents][populate][voucher27][fields][4]': 'path',
                },
            }
        return authenticatedFetch({
            path: `/entries/${id}`,
            ...params,
        })
    }

    async function fetchOneEntry(id) {
        return authenticatedFetch({
            path: `/entries/${id}`,
        })
    }

    async function fetchCompaniesForCompaniesPage(search) {
        const searchParam = search ? { search } : null
        const params =
            {
                params: {
                    ...searchParam,
                    'populate[entries][fields][0]': 'id',
                    'populate[entries][fields][1]': 'status',
                },
            }
        const companies = await authenticatedFetch({
            path: `/companies`,
            ...params,
        })
        return companies
    }

    function isAuthenticated() {
        return authStorage.isAuthenticated()
    }

    const fetcher = {
        fetch: contextualFetch,
        authenticatedFetch,
        fetchDocument,
        fetchDocumentForDocumentPage,
        fetchUser,
        fetchOneCompany,
        fetchCompanyName,
        fetchMinimalCompanyInfo,
        fetchOneEntry,
        fetchEntryForEntryPage,
        fetchCompaniesForCompaniesPage,
        fetchEntriesForEntriesPage,
        authenticate,
        isAuthenticated,
    }

    return (
        <FetchContext.Provider value={fetcher}>
            {children}
        </FetchContext.Provider>
    )
}
