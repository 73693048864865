/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useMemo, useState } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// @mui material components
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Custom styles for the MDSnackbar
import MDSnackbarIconRoot from 'components/MDSnackbar/MDSnackbarIconRoot'

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from 'context/material-ui'

function MDSnackbar({
    color,
    icon,
    title,
    dateTime,
    content,
    close,
    duration,
    bgWhite,
    ...rest
}) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller

    let titleColor
    let dateTimeColor
    let dividerColor

    if (bgWhite) {
        titleColor = color
        dateTimeColor = 'dark'
        dividerColor = false
    }
    else if (color === 'light') {
        titleColor = darkMode ? 'inherit' : 'dark'
        dateTimeColor = darkMode ? 'inherit' : 'text'
        dividerColor = false
    }
    else {
        titleColor = 'white'
        dateTimeColor = 'white'
        dividerColor = true
    }

    const [progress, setProgress] = useState(0)
    const start = useMemo(() => {
        const now = new Date()
        return now.getTime()
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
            if (Number.isNaN(duration)) {
                return
            }

            setProgress((prevProgress) => {
                const now = new Date()
                const time = now.getTime()
                const delta = time - start
                const newProgress = delta / duration * 100
                return newProgress >= 100 ? 100 : newProgress
            })
        }, 50)
        return () => {
            clearInterval(timer)
        }
    }, [])

    return (
        <Snackbar
            TransitionComponent={Fade}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            {...rest}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={close}>
                    <Icon fontSize="small">close</Icon>
                </IconButton>
            }
            onClose={close}
            autoHideDuration={duration}
        >
            <MDBox
                variant={bgWhite ? 'contained' : 'gradient'}
                bgColor={bgWhite ? 'white' : color}
                minWidth="21.875rem"
                maxWidth="100%"
                shadow="md"
                borderRadius="md"
                p={1}
                sx={{
                    backgroundColor: ({ palette }) =>
                        darkMode ? palette.background.card : palette[color] || palette.white.main,
                }}
            >
                <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    color="dark"
                    p={1.5}
                >
                    <MDBox display="flex" alignItems="center" lineHeight={0}>
                        <MDSnackbarIconRoot fontSize="small" ownerState={{ color, bgWhite }}>
                            {icon}
                        </MDSnackbarIconRoot>
                        <MDTypography
                            variant="button"
                            fontWeight="medium"
                            color={titleColor}
                            textGradient={bgWhite}
                        >
                            {title}
                        </MDTypography>
                    </MDBox>
                    <MDBox display="flex" alignItems="center" lineHeight={0}>
                        <MDTypography variant="caption" color={dateTimeColor}>
                            {dateTime}
                        </MDTypography>
                        <MDBox
                            sx={{
                                position: 'relative',
                                display: 'inline-flex',
                                marginLeft: 1,
                            }}
                        >
                            <CircularProgress
                                variant="determinate"
                                value={progress}
                                size={24}
                                sx={{
                                    color: ({ palette: { dark, white } }) =>
                                        (bgWhite && !darkMode) || color === 'light' ? dark.main : white.main,
                                    [`& .${circularProgressClasses.circle}`]: {
                                        strokeLinecap: 'round',
                                    },
                                }}
                            />
                            <MDBox
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Icon
                                    sx={{
                                        color: ({ palette: { dark, white } }) =>
                                            (bgWhite && !darkMode) || color === 'light' ? dark.main : white.main,
                                        cursor: 'pointer',
                                        transform: 'translateY(-1px)',
                                    }}
                                    onClick={close}
                                >
                                    close
                                </Icon>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <Divider sx={{ margin: 0 }} light={dividerColor} />
                <MDBox
                    p={1.5}
                    sx={{
                        fontSize: ({ typography: { size } }) => size.sm,
                        color: ({ palette: { white, text } }) => {
                            let colorValue = bgWhite || color === 'light' ? text.main : white.main

                            if (darkMode) {
                                colorValue = color === 'light' ? 'inherit' : white.main
                            }

                            return colorValue
                        },
                    }}
                >
                    {content}
                </MDBox>
            </MDBox>
        </Snackbar>
    )
}

// Setting default values for the props of MDSnackbar
MDSnackbar.defaultProps = {
    bgWhite: false,
    color: 'info',
}

// Typechecking props for MDSnackbar
MDSnackbar.propTypes = {
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
        'light',
    ]),
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    dateTime: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    close: PropTypes.func.isRequired,
    bgWhite: PropTypes.bool,
}

export default MDSnackbar
