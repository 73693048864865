/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useMemo, useState } from 'react'
// react-router-dom components
import { Link, useMatches, matchPath, useLocation } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material'
import Icon from '@mui/material/Icon'
// import RouteTitle from './RouteTitle'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import routes from '../../routes'
import DatumLoader from 'components/loaders/DatumLoader'

// function getTitle(route, match) {
//   const getter = route.handle?.getTitle
//   if (!getter) {
//     return null
//   }
//   const title = getter(match.params)
//   return title
// }

// const RouteTitle = ({ route, match }) => {
//   const title = getTitle(route, match)
//   return <>{title}</>
// }

const Breadcrumbs = ({
    icon,
    // title,
    // route,
    light,
}) => {
    const [titles, setTitles] = useState({})

    // const routeParts = route.slice(0, -1)

    // console.log('breadcrumbs routes', routes)

    const matches = useMatches()

    const location = useLocation()
    // setTitles(matches.map((match) => ({
    //   [match.pathname]: 'xyz',
    // })))
    // const crumbs = matches
    //   // first get rid of any matches that don't have handle and crumb
    //   .filter((match) => Boolean(match.crumb))
    //   // now map them into an array of elements, passing the loader
    //   // data to each one
    //   .map((match) => match.crumb(match.data))


    // return (
    //   <ol>
    //     {crumbs.map((crumb, index) => (
    //       <li key={index}>{crumb}</li>
    //     ))}
    //   </ol>
    // )

    const { pathname } = location

    // // const currentPathPart = fullPath.split('/').slice(1)
    // const routeParts = pathname.split('/').slice(1)
    // // const title = routeParts[routeParts.length - 1]
    // console.log('route parts', routeParts)

    // const route = routes.find(route => route.route === pathname)
    // console.log('selected route', route)

    // const titleMatches = matches.filter(match => match?.handle?.title)
    // // const segments = matches.filter(match => match.handle?.crumb)
    // console.log('breadcrumbs with titles', titleMatches)

    // const crumbs = []

    // matches.forEach((match) => {
    //   console.log('crumbs match', match)
    // })

    // const segments = pathname.split('/')
    // segments.forEach((segment, index) => {
    //   const segmentPath = segments.slice(0, index).concat(segment).join('/').replace('//', '/')
    //   console.log('current segment', {pathname, segment, index, segmentPath})
    //   const match = matchPath({ path: segmentPath, end: false }, pathname)
    //   if (match) {
    //     crumbs.push(match)
    //   }
    // })

    // function matchRoutes(routes, basePath) {
    //   const crumbs = []
    //   routes.forEach((route) => {
    //     // if (route.index) {
    //     //   return
    //     // }

    //     const partialPath = [basePath, route.path].join('/').replace('//', '/')
    //     const match = matchPath({ path: partialPath, end: false }, pathname)
    //     if (match) {
    //       if (route.handle?.crumb) {
    //         const title = getTitle(route, match)
    //         crumbs.push({ route, match, title })
    //       }

    //       if (route.children) {
    //         const childMatches = matchRoutes(route.children, partialPath)
    //         crumbs.push(...childMatches)
    //       }
    //     }
    //   })
    //   return [...crumbs]
    // }

    // const crumbs = [
    //   {
    //     path: '/companies',
    //     title: 'חברות',
    //   },
    // ]

    function replaceAll(source, needle, replacement) {
        const target = source.replaceAll(needle, replacement)
        const result = target === source ? target : replaceAll(target, needle, replacement)
        return result
    }

    function matchRoutes(routes, pathname, basePath) {
        const crumbs = []
        routes.forEach((route) => {
            // if (route.index) {
            //   return
            // }

            const partialPath = replaceAll([basePath, route.path].join('/'), '//', '/')
            const match = matchPath({ path: partialPath, end: false }, pathname)
            if (match) {
                if (
                    route.handle?.crumb
                    && (route.handle.title || route.handle.getTitle)
                ) {
                    // const title = getTitle(route, match)
                    crumbs.push({ route, match }) //, title })
                    // setTitles({
                    //   ...titles,
                    //   [match.pathname]: 'fetching',
                    // })
                    // const getTitle = route.handle.getTitle
                    // if (getTitle) {
                    //   getTitle().then((fetchedTitle) => setTitles(
                    //     {
                    //       ...titles,
                    //       [match.pathname]: fetchedTitle,
                    //     }
                    //   ))
                    // }
                }

                if (route.children) {
                    const childMatches = matchRoutes(route.children, pathname, partialPath)
                    crumbs.push(...childMatches)
                }
            }
        })
        return [...crumbs]
    }

    const crumbs = useMemo(() => matchRoutes(routes, pathname), [location])

    useEffect(() => {
        const initialTitles = crumbs.reduce((result, { route, match }) => ({
            ...result,
            [match.pathname]: route.handle?.title || <DatumLoader />,
        }))
        setTitles(initialTitles)
        const promises = crumbs.map(({ route, match }) => {
            const getTitle = route.handle.getTitle
            const getTitlePromise = getTitle
                ? getTitle(match.params)
                : Promise.resolve(route.handle.title)
            return getTitlePromise.then((title) => ({
                path: match.pathname,
                title: title || route.handle.title,
            }))
        })
        Promise.all(promises).then((results) => {
            const aggregateTitles = results.reduce((memo, { path, title }) => ({
                ...memo,
                [path]: title,
            }), {})
            setTitles(aggregateTitles)
        })
    }, [location, crumbs])

    return (
        <MDBox mr={{ xs: 0, xl: 16 }}>
            <MuiBreadcrumbs
                sx={{
                    '& .MuiBreadcrumbs-separator': {
                        color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
                    },
                }}
            >
                {crumbs.map(({ route, match }) => (
                    <Link to={match.pathname} key={match.pathname}>
                        <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="regular"
                            // textTransform="capitalize"
                            color={light ? 'white' : 'dark'}
                            opacity={light ? 0.8 : 0.5}
                            sx={{
                                lineHeight: 0,
                                fontSize: '1.2rem',
                            }}
                        >
                            {titles[match.pathname] || <DatumLoader />}
                        </MDTypography>
                    </Link>
                ))}
            </MuiBreadcrumbs>
        </MDBox>
    )
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
    light: false,
}

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
    icon: PropTypes.node,
    // icon: PropTypes.node.isRequired,
    // title: PropTypes.string.isRequired,
    // route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    light: PropTypes.bool,
}

export default Breadcrumbs
