import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useInterval from 'use-interval'
import { grey } from '@mui/material/colors'
import { reportError } from 'units/logging/errors'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'

export default function ErrorFallback({ error, resetErrorBoundary }) {

    const totalSeconds = 1
    const [seconds, setSeconds] = useState(totalSeconds)

    useEffect(() => {
        reportError(error)
    }, [error])

    useInterval(() => {
        setSeconds((s) => s - 1)
    }, seconds <= 0 ? null : 1000)

    const navigate = useNavigate()
    if (seconds <= 0) {
        navigate(0)
    }

    return (
        <MDBox
            xs={12}
            md={6}
            lg={6}
        >
            <MDBox component="section" mb={3}>
                <h2>ארעה שגיאה</h2>
                <p>השגיאה דווחה למערכת באופן אוטומטי.</p>
                <p>נעבור לעמוד בעוד {seconds} שניות. אפשר להקדים את המעבר בלחיצה על הכפתור.</p>
                <MDBox my={1}>
                    <MDButton
                        onClick={resetErrorBoundary}
                        color="primary"
                    >מעבר לעמוד</MDButton>
                </MDBox>
            </MDBox>
            <MDBox component="section">
                <h2>השגיאה</h2>
                <MDBox
                    component="pre"
                    color={grey[100]}
                    bgColor={grey[900]}
                    py={1}
                    px={2}
                    borderRadius="md"
                    style={{
                        direction: 'ltr',
                        textWrap: 'inherit',
                    }}
                >{error.message}</MDBox>
            </MDBox>
        </MDBox>
    )
}
