import MDBadge from 'components/MDBadge'

export const statuses = {
    new: {
        text: 'חדש',
        color: 'info',
    },
    ready: {
        text: 'מוכן',
        color: 'warning',
    },
    compiled: {
        text: 'נעול',
        color: 'secondary',
    },
    sent: {
        text: 'שודר',
        color: 'info',
    },
    invalid: {
        text: 'לא תקין',
        color: 'error',
    },
    missing_details: {
        text: 'חסרים פרטים',
        color: 'error',
    },
    valid: {
        text: 'תקין',
        color: 'success',
    },
    empty: {
        text: 'ריק',
        color: 'info',
    },
    default(text) {
        return {
            text,
            color: 'info',
        }
    },
}

function StatusBadge({ status, ...rest }) {
    const normalizedStatus = status?.toLowerCase()

    const { text, color } =
        normalizedStatus && statuses.hasOwnProperty(normalizedStatus)
            ? statuses[normalizedStatus]
            : statuses.default(status)

    return text && (
        <MDBadge
            badgeContent={text}
            color={color}
            circular={true}
            variant="contained"
            size="lg"
            {...rest}
        />
    )
}

export default StatusBadge
