import { redirect } from 'react-router-dom'
import Icon from '@mui/material/Icon'
import CompaniesPage from './components/companies/CompaniesPage'
import CompanyForm from './pages/company-form/companyForm'
import EntriesPage from './components/entries/EntriesPage'
import EntryPage from './components/entries/EntryPage'
import AddDocument from './pages/AddDocument/AddDocument.component'
import { fetchDocumentForBreadcrumb, fetchMinimalCompanyInfo } from './helpers/fetch.helper'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import SignIn from 'pages/auth/sign-in/sign-in.component'
import AuthWrapper from 'components/auth/AuthWrapper'
import AuthStorage from './units/auth/AuthStorage'

const routes = [
    {
        element: <AuthWrapper />,
        children: [
            {
                path: '/',
                element: <DashboardLayout />,
                handle: {
                    icon: 'home',
                    title: <Icon>home</Icon>,
                    crumb: true,
                },
                children: [
                    {
                        index: true,
                        loader: async () => {
                            return redirect('/companies')
                        },
                    },
                    {
                        path: 'companies',
                        children: [
                            {
                                element: <CompaniesPage />,
                                index: true,
                                id: 'companies',
                                handle: {
                                    title: 'החברות שלי',
                                    icon: 'store',
                                    nav: true,
                                    crumb: true,
                                },
                            },
                            {
                                path: ':companyId',
                                element: <CompanyForm />,
                                handle: {
                                    key: 'company',
                                    getTitle: async ({ companyId }) => {
                                        if (companyId === 'add') {
                                            return 'חברה חדשה'
                                        }

                                        const authStorage = new AuthStorage()

                                        let company
                                        if (!authStorage.isAuthenticated()) {
                                            company = null
                                        }
                                        else {
                                            company = await fetchMinimalCompanyInfo(companyId)
                                        }
                                        return company?.companyName || 'ללא שם'
                                    },
                                    crumb: true,
                                },
                            },
                            // {
                            //   path: 'add',
                            //   element: <CompanyForm mode="create" />,
                            //   handle: {
                            //     key: 'company-add',
                            //     title: 'חברה חדשה',
                            //   },
                            // },
                            {
                                path: ':companyId/entries',
                                element: <EntriesPage />,
                                handle: {
                                    key: 'company-entries',
                                    title: 'רשומות',
                                    // getTitle: async (params) => {
                                    //   const company = await fetchOneCompany(params.companyId)
                                    //   return company?.companyName || '?'
                                    // },
                                    crumb: true,
                                },
                            },
                            {
                                path: ':companyId/entries/:entryId',
                                element: <EntryPage />,
                                handle: {
                                    key: 'company-entry',
                                    getTitle: async ({ entryId }) => {
                                        return `רשומה ${entryId}`
                                    },
                                    crumb: true,
                                },
                                children: [
                                    {
                                        path: 'documents/:documentId',
                                        // element: <DocumentPage mode="view" />,
                                        element: <AddDocument editMode={false} />,
                                        handle: {
                                            key: 'company-entry-document',
                                            getTitle: async (params) => {
                                                const { documentId } = params
                                                let documentName = 'מסמך'
                                                try {
                                                    const document = await fetchDocumentForBreadcrumb(documentId)
                                                    const kindOrType = document?.kind || document?.type
                                                    if (kindOrType === 'check') {
                                                        documentName = 'שיק'
                                                    }
                                                    else if (kindOrType === 'voucher') {
                                                        documentName = 'שובר'
                                                    }
                                                    else if (kindOrType?.startsWith('voucher')) {
                                                        documentName = 'שובר'
                                                    }
                                                }
                                                catch (error) {
                                                    console.error(error)
                                                }
                                                return `${documentName} ${documentId}`
                                            },
                                            crumb: true,
                                        },
                                        // children: [
                                        //   {
                                        //     path: 'edit',
                                        //     element: <AddDocument mode="edit" />,
                                        //     handle: {
                                        //       title: 'עריכה',
                                        //     },
                                        //     crumb: true,
                                        //   },
                                        // ],
                                        // editMode: false,
                                    },
                                    //   {
                                    //     key: 'company-entry-document',
                                    //     path: 'documents/add/:documentId',
                                    //     element: <AddDocument />,
                                    //     editMode: false,
                                    //   },
                                    //   {
                                    //     key: 'company-entry-document-edit',
                                    //     path: 'documents/edit/:documentId',
                                    //     element: <AddDocument />,
                                    //     editMode: true,
                                    //   },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '/auth',
        // element: <IllustrationLayout />,
        children: [
            {
                index: true,
                loader: async () => {
                    return redirect('/auth/sign-in')
                },
            },
            {
                path: 'sign-in',
                element: <SignIn />,
                id: 'auth-sign-in',
            },
        ],
    },
]
export default routes
