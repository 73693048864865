/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { forwardRef } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// Custom styles for MDBox
import MDBoxRoot from 'components/MDBox/MDBoxRoot'

const MDBox = forwardRef(
    ({ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow, ...rest }, ref) => (
        <MDBoxRoot
            {...rest}
            ref={ref}
            ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow }}
        />
    ),
)

// Setting default values for the props of MDBox
MDBox.defaultProps = {
    variant: 'contained',
    bgColor: 'transparent',
    color: 'dark',
    opacity: 1,
    borderRadius: 'none',
    shadow: 'none',
    coloredShadow: 'none',
}

// Typechecking props for the MDBox
MDBox.propTypes = {
    variant: PropTypes.oneOf(['contained', 'gradient']),
    bgColor: PropTypes.string,
    color: PropTypes.string,
    opacity: PropTypes.number,
    borderRadius: PropTypes.string,
    shadow: PropTypes.string,
    coloredShadow: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'light',
        'dark',
        'none',
    ]),
}

export default MDBox
