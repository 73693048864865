/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect } from 'react'

// react-router-dom components
import { useLocation } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from 'context/material-ui'

function PageLayout({ background, children }) {
    const [, dispatch] = useMaterialUIController()
    const { pathname } = useLocation()

    useEffect(() => {
        setLayout(dispatch, 'page')
    }, [pathname])

    return (
        <MDBox
            width="100vw"
            height="100%"
            minHeight="100vh"
            bgColor={background}
            sx={{ overflowX: 'hidden' }}
        >
            {children}
        </MDBox>
    )
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
    background: 'default',
}

// Typechecking props for the PageLayout
PageLayout.propTypes = {
    background: PropTypes.oneOf(['white', 'light', 'default']),
    children: PropTypes.node.isRequired,
}

export default PageLayout
